/** @type {import('tailwindcss').Config} */
export const content = [
  './components/*.{js,vue,ts}',
  './components/**/*.{js,vue,ts}',
  './components/**/**/*.{js,vue,ts}',
  './components/**/**/**/*.{js,vue,ts}',
  './layouts/**/*.vue',
  './pages/**/*.vue',
  './*.vue',
  './plugins/**/*.{js,ts}',
  './nuxt.config.{js,ts}'
]
export const theme = {
  fontFamily: {
    main: ['"Montserrat", Arial, Helvetica, sans-serif'],
    headerThin: ['"Poppins-Thin", Arial, Helvetica, sans-serif'],
    headerRegular: ['"Poppins-Regular", Arial, Helvetica, sans-serif'],
    headerBold: ['"Poppins-Bold", Arial, Helvetica, sans-serif']
  },
  extend: {
    screens: {
      sm: '600px',
      md: '960px',
      lg: '1200px',
      xl: '1440px',
      '2xl': '1600px'
    },
    colors: {
      main: {
        DEFAULT: '#ffffff',
        50: '#F2F2F2',
        100: '#E6E6E6',
        200: '#CCCCCC',
        300: '#B3B3B3',
        400: '#999999',
        500: '#808080',
        600: '#666666',
        700: '#4D4D4D',
        800: '#333333',
        900: '#1A1A1A',
        950: '#0D0D0D'
      },
      background: {
        DEFAULT: '#300F7B',
        50: '#F0EBFA',
        100: '#E1D6F5',
        200: '#C3AEEA',
        300: '#A585E0',
        400: '#875DD5',
        500: '#6934CB',
        600: '#542AA2',
        700: '#3F1F7A',
        800: '#2A1551',
        900: '#150A29',
        950: '#0A0514'
      },
      primary: {
        DEFAULT: '#3700B3',
        50: '#EDE5FF',
        100: '#DBCCFF',
        200: '#B899FF',
        300: '#9466FF',
        400: '#7033FF',
        500: '#4D00FF',
        600: '#3D00CC',
        700: '#2E0099',
        800: '#1F0066',
        900: '#0F0033',
        950: '#08001A'
      },
      secondary: {
        DEFAULT: '#03d8c3',
        50: '#E6FFFC',
        100: '#CDFEF9',
        200: '#9BFDF4',
        300: '#68FDEE',
        400: '#36FCE8',
        500: '#04FBE2',
        600: '#03C9B5',
        700: '#029788',
        800: '#02645B',
        900: '#01322D',
        950: '#001917'
      },
      accent: {
        DEFAULT: '#895dee',
        50: '#F1E6FE',
        100: '#E2CDFE',
        200: '#C69CFC',
        300: '#A96AFB',
        400: '#8C38FA',
        500: '#6F06F9',
        600: '#5905C7',
        700: '#430495',
        800: '#2D0363',
        900: '#160132',
        950: '#0B0119'
      }
    },
    backgroundColor: {
      text: {
        DEFAULT: '#ffffff',
        50: '#F2F2F2',
        100: '#E6E6E6',
        200: '#CCCCCC',
        300: '#B3B3B3',
        400: '#999999',
        500: '#808080',
        600: '#666666',
        700: '#4D4D4D',
        800: '#333333',
        900: '#1A1A1A',
        950: '#0D0D0D'
      },
      background: {
        DEFAULT: '#300F7B',
        50: '#F0EBFA',
        100: '#E1D6F5',
        200: '#C3AEEA',
        300: '#A585E0',
        400: '#875DD5',
        500: '#6934CB',
        600: '#542AA2',
        700: '#3F1F7A',
        800: '#2A1551',
        900: '#150A29',
        950: '#0A0514'
      },
      primary: {
        DEFAULT: '#3700B3',
        50: '#EDE5FF',
        100: '#DBCCFF',
        200: '#B899FF',
        300: '#9466FF',
        400: '#7033FF',
        500: '#4D00FF',
        600: '#3D00CC',
        700: '#2E0099',
        800: '#1F0066',
        900: '#0F0033',
        950: '#08001A'
      },
      secondary: {
        DEFAULT: '#03d8c3',
        50: '#E6FFFC',
        100: '#CDFEF9',
        200: '#9BFDF4',
        300: '#68FDEE',
        400: '#36FCE8',
        500: '#04FBE2',
        600: '#03C9B5',
        700: '#029788',
        800: '#02645B',
        900: '#01322D',
        950: '#001917'
      },
      accent: {
        DEFAULT: '#895dee',
        50: '#F1E6FE',
        100: '#E2CDFE',
        200: '#C69CFC',
        300: '#A96AFB',
        400: '#8C38FA',
        500: '#6F06F9',
        600: '#5905C7',
        700: '#430495',
        800: '#2D0363',
        900: '#160132',
        950: '#0B0119'
      },
      overlay: '#000000bf'
    },
    borderColor: {
      text: {
        DEFAULT: '#ffffff',
        50: '#F2F2F2',
        100: '#E6E6E6',
        200: '#CCCCCC',
        300: '#B3B3B3',
        400: '#999999',
        500: '#808080',
        600: '#666666',
        700: '#4D4D4D',
        800: '#333333',
        900: '#1A1A1A',
        950: '#0D0D0D'
      },
      background: {
        DEFAULT: '#300F7B',
        50: '#F0EBFA',
        100: '#E1D6F5',
        200: '#C3AEEA',
        300: '#A585E0',
        400: '#875DD5',
        500: '#6934CB',
        600: '#542AA2',
        700: '#3F1F7A',
        800: '#2A1551',
        900: '#150A29',
        950: '#0A0514'
      },
      primary: {
        DEFAULT: '#3700B3',
        50: '#EDE5FF',
        100: '#DBCCFF',
        200: '#B899FF',
        300: '#9466FF',
        400: '#7033FF',
        500: '#4D00FF',
        600: '#3D00CC',
        700: '#2E0099',
        800: '#1F0066',
        900: '#0F0033',
        950: '#08001A'
      },
      secondary: {
        DEFAULT: '#03d8c3',
        50: '#E6FFFC',
        100: '#CDFEF9',
        200: '#9BFDF4',
        300: '#68FDEE',
        400: '#36FCE8',
        500: '#04FBE2',
        600: '#03C9B5',
        700: '#029788',
        800: '#02645B',
        900: '#01322D',
        950: '#001917'
      },
      accent: {
        DEFAULT: '#895dee',
        50: '#F1E6FE',
        100: '#E2CDFE',
        200: '#C69CFC',
        300: '#A96AFB',
        400: '#8C38FA',
        500: '#6F06F9',
        600: '#5905C7',
        700: '#430495',
        800: '#2D0363',
        900: '#160132',
        950: '#0B0119'
      }
    },
    boxShadow: {
      main: '1px 1px 4px 0px rgba(0, 0, 0, 0.3)',
      light: '0 6px 6px rgba(0,0,0,.04)'
    },
    padding: {
      '1/12': '8.333333333333333%',
      '2/12': '16.666666666666666%',
      '3/12': '25%',
      '4/12': '33.33333333333333%',
      '5/12': '41.66666666666667%',
      '6/12': '50%',
      '7/12': '58.33333333333334%',
      '8/12': '66.66666666666666%',
      '9/12': '75%',
      '10/12': '83.33333333333334%',
      '11/12': '91.66666666666666%'
    },
    margin: {
      '1/12': '8.333333333333333%',
      '2/12': '16.666666666666666%',
      '3/12': '25%',
      '4/12': '33.33333333333333%',
      '5/12': '41.66666666666667%',
      '6/12': '50%',
      '7/12': '58.33333333333334%',
      '8/12': '66.66666666666666%',
      '9/12': '75%',
      '10/12': '83.33333333333334%',
      '11/12': '91.66666666666666%'
    },
    width: {
      '1/12': '8.333333333333333%',
      '2/12': '16.666666666666666%',
      '3/12': '25%',
      '4/12': '33.33333333333333%',
      '5/12': '41.66666666666667%',
      '6/12': '50%',
      '7/12': '58.33333333333334%',
      '8/12': '66.66666666666666%',
      '9/12': '75%',
      '10/12': '83.33333333333334%',
      '11/12': '91.66666666666666%'
    },
    maxWidth: {
      '1/12': '8.333333333333333%',
      '2/12': '16.666666666666666%',
      '3/12': '25%',
      '4/12': '33.33333333333333%',
      '5/12': '41.66666666666667%',
      '6/12': '50%',
      '7/12': '58.33333333333334%',
      '8/12': '66.66666666666666%',
      '9/12': '75%',
      '10/12': '83.33333333333334%',
      '11/12': '91.66666666666666%'
    }
  }
}
export const plugins = []
