import revive_payload_client_4sVQNw7RlN from "/usr/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/usr/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import autoFormat_client_h4lt4CIgAm from "/usr/app/plugins/autoFormat.client.ts";
import fileURL_5GVlqeg3sL from "/usr/app/plugins/fileURL.ts";
import imageURL_KrNiNsvIrP from "/usr/app/plugins/imageURL.ts";
import preview_client_ayxl3VrNC6 from "/usr/app/plugins/preview.client.ts";
import slug_TUYMPnL2xi from "/usr/app/plugins/slug.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  autoFormat_client_h4lt4CIgAm,
  fileURL_5GVlqeg3sL,
  imageURL_KrNiNsvIrP,
  preview_client_ayxl3VrNC6,
  slug_TUYMPnL2xi
]