<template>
  <div>
    <Navbar />
    <NuxtPage />
    <Footer />
    <CookieBanner />
  </div>
</template>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>
