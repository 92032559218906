import { default as _91slug_93T5ITLsEbU0Meta } from "/usr/app/pages/[slug].vue?macro=true";
import { default as indexfzSMqasWUtMeta } from "/usr/app/pages/index.vue?macro=true";
import { default as _91slug_93Bazmm0hfKxMeta } from "/usr/app/pages/news/[slug].vue?macro=true";
import { default as _91slug_93vQRQMIRkAfMeta } from "/usr/app/pages/team/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93T5ITLsEbU0Meta?.name ?? "slug",
    path: _91slug_93T5ITLsEbU0Meta?.path ?? "/:slug()",
    meta: _91slug_93T5ITLsEbU0Meta || {},
    alias: _91slug_93T5ITLsEbU0Meta?.alias || [],
    redirect: _91slug_93T5ITLsEbU0Meta?.redirect,
    component: () => import("/usr/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfzSMqasWUtMeta?.name ?? "index",
    path: indexfzSMqasWUtMeta?.path ?? "/",
    meta: indexfzSMqasWUtMeta || {},
    alias: indexfzSMqasWUtMeta?.alias || [],
    redirect: indexfzSMqasWUtMeta?.redirect,
    component: () => import("/usr/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Bazmm0hfKxMeta?.name ?? "news-slug",
    path: _91slug_93Bazmm0hfKxMeta?.path ?? "/news/:slug()",
    meta: _91slug_93Bazmm0hfKxMeta || {},
    alias: _91slug_93Bazmm0hfKxMeta?.alias || [],
    redirect: _91slug_93Bazmm0hfKxMeta?.redirect,
    component: () => import("/usr/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vQRQMIRkAfMeta?.name ?? "team-slug",
    path: _91slug_93vQRQMIRkAfMeta?.path ?? "/team/:slug()",
    meta: _91slug_93vQRQMIRkAfMeta || {},
    alias: _91slug_93vQRQMIRkAfMeta?.alias || [],
    redirect: _91slug_93vQRQMIRkAfMeta?.redirect,
    component: () => import("/usr/app/pages/team/[slug].vue").then(m => m.default || m)
  }
]